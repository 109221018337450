import React from 'react';
import './Footer.css';
import FacebookIcon from '../images/facebook-icon.png';
import InstagramIcon from '../images/instagram-icon.png';
import LinkedInIcon from '../images/linkedin-icon.png';

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="social-links">
        <a href="https://www.facebook.com/profile.php?id=61556025737229&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/rioslampa.asociados?igsh=MWtvajA5OTZ4NThiaw==" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <a href="http://www.linkedin.com/in/franco-rios-lampa-7a88592ab" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
      </div>
      <p>&copy; 2024 FR Soluciones legales. Todos los derechos reservados.</p>
    </footer>
  );
};

export default Footer;