import React, { useState, useEffect } from 'react';
import './Consultation.css';
import FormImage from '../images/consultation.jpeg';

const Consultation = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [alert, setAlert] = useState({ show: false, type: '', message: '' });


  useEffect(() => {
    if (!window.emailjs) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js';
      script.onload = () => {
        window.emailjs.init('kQMMscMM6mTK5Qdjd');
      };
      document.body.appendChild(script);
    } else {
      window.emailjs.init('kQMMscMM6mTK5Qdjd');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.emailjs) {
      window.emailjs.send('default_service', 'template_r74kwxh', formData)
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          showAlert('success', 'La consulta fue enviada correctamente.');
          setTimeout(() => {
            window.location.replace('/');
          }, 5000);
        }, (error) => {
          console.log('FAILED...', error);
          showAlert('error', 'Hubo un error al enviar el mensaje. Por favor, inténtelo de nuevo.');
        });

    } else {
      showAlert('error', 'EmailJS no está disponible en este momento.');
    }
  };

  const showAlert = (type, message) => {
    setAlert({ show: true, type, message });
    setTimeout(() => {
      setAlert({ show: false, type: '', message: '' });
    }, 5000);
  };
  return (
    <div className="container">
      <div className="content">
        <div className="consultation-container">
          <div className="image-container">
            <img src={FormImage} alt="Formulario Imagen" className="form-image" />
          </div>
          <div className="form-container">
            <h2>Formulario de Consulta</h2>
            {alert.show && (
              <div className={`alert ${alert.type}`}>
                {alert.message}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nombre:</label>
                <input type="text" id="name" name="name" required value={formData.name}
                  onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico:</label>
                <input type="email" id="email" name="email" required
                  value={formData.email}
                  onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="message">Consulta:</label>
                <textarea id="message" name="message" rows="5" value={formData.message}
                  onChange={handleChange} required></textarea>
              </div>
              <button type="submit" className="submit-button">Enviar Consulta</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Consultation;
