import React, { useState, useEffect } from 'react';
import './Home.css';
import justiceImg from '../images/home_justice.jpeg';
import ProfilePhoto from '../images/profile.jpeg';
import BookPhoto from '../images/book.jpeg';
import PracticeArea from './PracticeArea';
import Carousel from './Carousel';
import derechoSocietario from '../images/derecho-societario.png';
import derechoLaboral from '../images/derecho-laboral.png';
import derechoComercial from '../images/derecho-comercial.png';
import derechoFamiliar from '../images/derecho-familia.png';
import derechoSucesorio from '../images/derecho-sucesorio.png';
import derechoDeDano from '../images/derecho-dano.png';
import derechoDeConsumidor from '../images/derecho-consumidor.png';
import derechoPrevisional from '../images/derecho-previsional.png';
import derechoPenal from '../images/derecho-penal.png';
import derechoSeguro from '../images/derecho-seguro.png';
import MissionImage1 from '../images/mission.jpeg';
import MissionImage2 from '../images/mission2.jpeg';

const Home = () => {
  const images = [ProfilePhoto, BookPhoto, justiceImg];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const missionImages = [MissionImage1, MissionImage2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const practiceAreas = [
    {
      title: 'Derecho Societario',
      services: [
        'Constitución e inscripción de sociedades',
        'Asesoramiento, prevención y representación en conflictos societarios',
      ],
      icon: derechoSocietario,
    },
    {
      title: 'Derecho Laboral',
      services: [
        'Asesoramiento preventivo para evitar conflictos judiciales',
        'Defensa técnica en instancias administrativas y judicial',
        'Asistencia a empresas y personas humanas',
      ],
      icon: derechoLaboral,
    },
    {
      title: 'Derecho Comercial',
      services: [
        'Asesoramiento sobre negocios entre personas, comercios y empresas',
        'Reclamos y juicios comerciales',
        'Procesos ejecutivos',
      ],
      icon: derechoComercial,
    },
    {
      title: 'Seguros',
      services: [
        'Asesoramiento en reclamaciones',
        'Acompañamiento en la instancia previa de mediación',
        'Patrocinio letrado en etapa judicial',
      ],
      icon: derechoSeguro,
    },
    {
      title: 'Derecho de Familia',
      services: [
        'Gestión de asuntos contenciosos',
        'Asesoramiento en las distintas formas de organización familiar',
      ],
      icon: derechoFamiliar,
    },
    {
      title: 'Derecho Sucesorio',
      services: [
        'Asesoramiento para una mayor celeridad, evitando demoras en los plazos más cortos posibles en el proceso sucesorio',
        'Solución de conflictos entre Herederos',
        'Cesiones y adquisiciones de derechos hereditarios',
      ],
      icon: derechoSucesorio,
    },
    {
      title: 'Derecho de Daño',
      services: [
        'Resolución de conflictos que surjan de la responsabilidad civil en general (contractual o extracontractual)',
        'Reclamos a Companias aseguradoras, Obras sociales, Seguros médicos',
        'Accidente de tránsito',
        'Mala praxis',
      ],
      icon: derechoDeDano,
    },
    {
      title: 'Derecho de Consumidor',
      services: [
        'Asesoramiento legal bajo la ley de protección al consumidor',
        'Representación en procesos administrativos y judiciales',
      ],
      icon: derechoDeConsumidor,
    },
    {
      title: 'Derecho Previsional',
      services: [
        'Jubilaciones',
        'Pensiones',
        'Reajuste de haberes previsionales',
      ],
      icon: derechoPrevisional,
    },
    {
      title: 'Derecho Penal',
      services: [
        'Asesoramiento integral en todas las instancias del proceso penal',
        'Defensa personalizada bajo estrategias jurídicas a la medida del caso',
        'Habeas corpus',
        'Querella y actor civil',
      ],
      icon: derechoPenal,
    },
  ];
  return (
    <div className="container">
      <div className="content">
        <div className="main-image-container">
          <img src={images[currentImageIndex]} alt="Rotating" />
          <div className="image-text">
            Dr. Rios Lampa<br />
            Soluciones Legales<br />
            <a href="consultation">
              <button className="consultation-button">Solicitar consulta</button>
            </a>
          </div>
        </div>
        <div className="home-intro" id="home">
          <h2 className="home-title">Estudio Jurídico</h2>
          <p className="home-par"> Es un Estudio Jurídico Integral de la provincia de Tucumán destacado por la dedicación intensiva a cada caso, obteniendo así el mas satisfactorio resultado y la mejor solución a cada requerimiento. </p>
          <p className="home-par">Nuestra prioridad es conocer y comprender a nuestros clientes, para ajustar nuestro trabajo a su perfil, necesidades e inspiración aplicando nuestras herramientas técnicas y habilidades para lograr resultados exitosos. </p>
          <p className="home-par">Bajo esa premisa, acompañamos a nuestros clientes en sus necesidades, deseos y proyecciones, sirviendo de soporte a través del asesoramiento integral para sostener y potenciar sus objetivos. </p>
          <p className="home-par">De acuerdo a la metodología de abordaje en los conflictos, se abarca las áreas del Derecho Societario, Laboral, Comercial, Seguros, familia, sucesiones, daños, consumidor, previsional y penal. </p>
        </div>
        <div className="mission-values-section" id="valores">
          <h2>Misión y Valores</h2>
          <div className="mission-values">


            <Carousel images={missionImages} />
            <div className='mission-values-text-container'>
              <h3>Nuestra Misión</h3>

              <p>
                El bufete de Abogados y asociados tiene como misión y valor esencial el resguardo y satisfacción de los intereses de nuestros clientes como sujetos de derechos y justiciables, desarrollamos nuestra labor privilegiando fundamentalmente la eficiencia, la lealtad y el profesionalismo, pilares fundamentales en el desarrollo de los negocios y cuestiones privadas que requieran intervención letrada.
                La conducta profesionista empleada con el sello de la excelencia, el pragmatismo que amerita cada caso y el asesoramiento integral es la misión que se renueva constantemente en cada asesoramiento.
              </p>
              <h3>Nuestros Valores</h3>
              <ul>
                <li>Compromiso con la excelencia.</li>
                <li>Integridad en todas nuestras interacciones.</li>
                <li>Respeto por nuestros clientes y colegas.</li>
                {/* Agrega más valores según sea necesario */}
              </ul>
            </div></div>
        </div>
        <div className="areas-section" id="areas">
          <h2>Áreas de práctica</h2>
          <div className="practice-areas">
            {practiceAreas.map((area, index) => (
              <PracticeArea key={index} title={area.title} services={area.services} icon={area.icon} />
            ))}
          </div>
        </div>
        <div className="alianza-section" id="alianza">
          <h2>Alianzas estratégicas</h2>
          <div className="alianza-description">
            Nuestro trabajo nos lleva a mantener vínculos y forjar relaciones con especialistas de distintas áreas, brindando un servicio multidisciplinario para cubrir necesidades de distinta índole.
            Disponiendo de corresponsales en todo el Noroeste Argentino, CABA y en Provincia de Buenos Aires.
            Comprendiendo una amplia extensión jurisdiccional formando parte de una red de estudios jurídicos disponemos de un asesoramiento integral y extensivo.
          </div>

        </div>
      </div>
    </div>
  );
};

export default Home;
