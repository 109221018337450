import React from 'react';
import logoWhatsapp from '../images/whatsapp-logo.png';
import './whatsapp.css';

const phoneNumber = '+5493815507922';

const WhatsAppBanner = () => {
  return (
    <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className="whatsapp-banner">
      <img src={logoWhatsapp} alt="Logo de WhatsApp" />
    </a>
  );
};

export default WhatsAppBanner;