import React from 'react';

const PracticeArea = ({ title, services, icon }) => (
  <div className="practice-area">
    <div className="area-icon-container">
      {icon && <img src={icon} alt={`${title} Icon`} className="area-icon" />}
    </div>
      <div className="area-description">
        <h3>{title}</h3>
        <ul>
          {services.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
    </div>
  </div>
);

export default PracticeArea;
