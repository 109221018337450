import React, { useState } from 'react';
import './Header.css';
import MenuButton from './MenuButton';
import PhoneImg from '../images/phone.png';
import LogoImg from '../images/brand-logo.png';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className={`header ${isMenuOpen ? 'open' : ''}`}>
      <div className="logo-container">
        <img src={LogoImg} alt="Logo" className="logo" />
      </div>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <MenuButton />
      </div>

      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/" onClick={closeMenu}>Inicio</a></li>
          <li><a href="/#home" onClick={closeMenu}>Estudio Jurídico</a></li>
          <li><a href="/#valores" onClick={closeMenu}>Valores y Misión</a></li>
          <li><a href="/#areas" onClick={closeMenu}>Áreas de práctica</a></li>
          <li><a href="/#alianza" onClick={closeMenu}>Alianzas estratégicas</a></li>
          <li>
            <div className="phone-icon">
              <div className="rounded-icon">
                <img src={PhoneImg} alt="Icono de teléfono" />
              </div>
              <div className="phone-number-text">(+54) 381-5507922</div>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
