// components/Contact.js

import React from 'react';
import './Contact.css'; // Asegúrate de que el nombre del archivo CSS coincida con el nuevo nombre del componente

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para manejar el envío del formulario (puedes agregar la lógica necesaria aquí)
  };

  return (
    <div className="contact-container">
      <h2>¡Contáctanos!</h2>

      <form onSubmit={handleSubmit} className="contact-form">
        <label>
          Nombre completo:
          <input type="text" name="fullName" required />
        </label>

        <label>
          Correo electrónico:
          <input type="email" name="email" required />
        </label>

        <label>
          Asunto:
          <input type="text" name="subject" required />
        </label>

        <label>
          Mensaje:
          <textarea name="message" required />
        </label>

        <button type="submit">Enviar mensaje</button>
      </form>

      <div className="contact-info">
        <h3>Información de Contacto:</h3>
        <p>Correo electrónico: contact@example.com</p>
        <p>Teléfono: [Número de teléfono]</p>
      </div>

      <div className="social-media">
        <h3>Redes Sociales:</h3>
        <p>Enlace a Facebook</p>
        <p>Enlace a Twitter</p>
        <p>Enlace a LinkedIn</p>
      </div>

      <p className="response-time">
        Tiempo de Respuesta: Nos esforzamos por responder en un plazo de 24 horas laborables.
      </p>

      <p className="thank-you">
        ¡Gracias por ponerte en contacto con nosotros! Esperamos recibir noticias tuyas pronto.
      </p>
    </div>
  );
};

export default Contact;
