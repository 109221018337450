import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import WhatsAppBanner from './components/WhatsappBanner';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Consultation from './components/Consultation';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contacto" element={<Contact />} />
            <Route path="/*" element={<Navigate to="/" />} />
            <Route path="/consultation" element={<Consultation/>} />
          </Routes>
        <Footer />
        <WhatsAppBanner />
      </div>
    </Router>
  );
}

export default App;
