import React, { useState, useEffect } from 'react';
import './Carousel.css';

const Carousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="carousel">
      <div className="carousel-image-container">
        <img src={images[currentImageIndex]} alt={`Imagen ${currentImageIndex + 1}`} />
      </div>

      {/* Contenedor de los puntos */}
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentImageIndex === index ? 'active' : ''}`}
            onClick={() => goToImage(index)} // Cambia la imagen al hacer clic
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
