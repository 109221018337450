import React from 'react';
import HamburgerIcon from '../images/hamburger-icon.png';


const MenuButton = () => {
  return (
      <img src={HamburgerIcon} alt='menu' />
  );
};

export default MenuButton;